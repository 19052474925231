import _ from 'lodash'
import { PENDING } from '../../reduxPromise'
import { createSelector } from 'reselect'
import { getIsUserAdmin, getIsUserPublic } from '../auth/selectors'

export const getIsLoading = state => state.platform.status === PENDING
export const getCurrentPlatform = state => _.get(state, 'platform.result', {})
export const getCurrentPlatformPermissions = state => _.get(state, 'platform.result.permissions')

export const getCurrentPlatformSelector = createSelector(
  [getCurrentPlatform],
  (platform) => platform
)

export const getCurrentPlatformPermissionsSelector = createSelector(
  [getCurrentPlatformPermissions],
  (platformPermissions) => platformPermissions
)

export const getCanUpdateReportsSelector = createSelector(
  [
    getCurrentPlatformPermissionsSelector,
    getIsUserAdmin,
    getIsUserPublic
  ],
  (platformPermissions, isAdminUser, isPublicUser) => {
    if (isAdminUser) return true
    return _.get(platformPermissions, 'updateReport') && (!isPublicUser || !_.get(platformPermissions, 'denyPublicUpdates', false))
  }
)
