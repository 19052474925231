import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class ReportConfigurationVersionsReducers {
  fetchAllRCVersions (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          results: [],
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          results: _.get(action, 'result')
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }
}

export default new ReportConfigurationVersionsReducers()
