import _, { create } from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, SUCCESS } from '../../reduxPromise'
import { getTestFormViewId } from '../auth/selectors'

export const getIsLoading = (state) => _.get(state, 'formViews.status') === PENDING
export const getFormViews = (state) => _.get(state, 'formViews.result')
export const getError = (state) => _.get(state, 'formViews.error')

const getPublicViews = createSelector([
  getFormViews
], (formViews) => {
  return _.filter(formViews, view => view.public)
})

export const getLatestPublicFormView = createSelector([
  getPublicViews,
  getTestFormViewId
], (formViews, testFormViewId = null) => {
  if (testFormViewId) return { id: testFormViewId }
  return _.head(formViews)
})
