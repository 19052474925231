import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class PlatformDetailsReducers {
  fetchPlatformDetails (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          result: {
            ...action.result
          },
          status: SUCCESS,
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }
}

export default new PlatformDetailsReducers()
