import * as constants from './constants'
import sdk from 'services/vigilanceHubService'
import _ from 'lodash'

class FormViewActions {
  fetchAllFormViews = () => ({
    type: constants.FETCH_ALL_FORMVIEWS,
    promise: (dispatch, getState) => {
      const { config, auth: { user } = {} } = getState()
      const organisationId = _.get(config, 'websiteConfig.vigilanceHubOrganisationId')
      const vigilanceHubSource = _.get(config, 'websiteConfig.vigilanceHubSource')
      const defaultVersion = _.get(config, 'websiteConfig.rcVersionId', 2)
      const userVersion = _.get(user, 'details.reportConfigurationVersion', false)
      const version = userVersion || defaultVersion
      const params = { public: true, version, source: vigilanceHubSource }
      return sdk.formView.fetchAllFormViews({ organisationId, params })
    }
  })
}

export default new FormViewActions()