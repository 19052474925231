import * as constants from './constants'
import sdk from 'services/vigilanceHubService'

class ReportConfigurationVersionsActions {
  fetchAllRCVersions = () => ({
    type: constants.GET_RC_VERSIONS,
    promise: () => {
      return sdk.reports.fetchReportConfigurationVersions()
    }
  })
}

export default new ReportConfigurationVersionsActions()