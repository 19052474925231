import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  status: undefined,
  results: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.GET_RC_VERSIONS:
      return reducers.fetchAllRCVersions(state, action)
    default:
      return state
  }
}
