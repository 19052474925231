import sdk from 'services/vigilanceHubService'

import * as constants from './constants'
import _ from 'lodash'
class PlatformDetailsActions {
  fetchPlatformDetails = () => ({
    type: constants.FETCH_PLATFORM_DETAILS,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const platformId = _.get(config, 'websiteConfig.vigilanceHubPlatformId')
      return sdk.platforms.fetchPlatform({ id: platformId })
    }
  })
}

export default new PlatformDetailsActions()
