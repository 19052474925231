import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  platform: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_PLATFORM_DETAILS:
      return reducers.fetchPlatformDetails(state, action)
    default:
      return state
  }
}
